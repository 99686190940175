import store from '../store'

export const getAllModules = () => {
    const modules = store.state.attendance.form
    const data = []
    Object.keys(modules).map(key => {
      if( modules[key] && (modules[key].id && modules[key].name)) {
        data.push({
          id: modules[key].id,
          name: modules[key].name
        })
      }
    })
    data.push({
      id: 'exame-personalizado',
      name: 'Exame personalizado'
    })
    return data
}

export const getAllModulesIds = () => {
  let modules = getAllModules()
  modules = modules.map(el => { return el.id })
  modules.push('exame-personalizado')
  return modules
}

export const getHistoryModulesByGroup = () => {
  return {
      'Arquivos': [
        'anexar-arquivos',
        'imagens-exames-essilor',
      ],
      'Anamnese oftalmológica completa': [
        'pre-anamnese',
        'queixa-principal',
        'historia-da-doenca-atual',
        'anamnese',
        'antecedentes-oftalmologicos',
        'antecedentes-pessoais',
        'antecedentes-familiares',
        'cirurgias-oftalmologicas-anteriores',
        'habitos-de-vida',
        'medicamentos-oftalmologicos-em-uso',
        'outros-medicamentos-em-uso',
        'alergias',
        'informacoes-adicionais-anamnese',
      ],
      'Triagem Benefício Visão': [
        'bvTriagem',
        'bvTesteAcuidadeVisual',
        'bvTesteIshihara',
      ],
      'Exames oftalmológicos': [
        'exame-fisico',
        'ectoscopia',
        'avsc-acuidade-visual-sem-correcao',
        'avcc-acuidade-visual-com-correcao',
        'avppsc-acuidade-visual-para-perto-sem-correcao',
        'avppcc-acuidade-visual-para-perto-com-correcao',
        'lensometria',
        'tonometria',
        'autorrefracao-din',
        'autorrefracao-est',
        'refracao-dinamica',
        'refracao-estatica',
        'acuidade-visual-para-longe-apos-refracao',
        'acuidade-visual-para-perto-apos-refracao',
        'biomicroscopia',
        'pressao-intraocular',
        'curva-tensional',
        'gonioscopia',
        'esquiascopia',
        'mapeamento-de-retina',
        'motilidade-ocular-extrinseca',
        'cover-test',
        'reflexos',
        'informacoes-adicionais',
        'campo-confrontacao',
        'teste-sensibilidade-mimica-facial',
      ],
      'Exames complementares': [
        'biometria',
        'retinografia',
        'paquimetria',
        'campo-visual',
        'oct-tomografia-de-coerencia-optica-macula',
        'oct-tomografia-de-coerencia-optica-nervo-optico',
        'oct-Tomografia-de-coerencia-optica-segmento-anterior',
        'oct-a-tomografia-de-coerencia-optica',
        'topografia',
        'tomografia-de-cornea-pentacam',
        'tomografia-de-cornea-galilei',
        'microscopia-especular-de-cornea',
        'indocianinografia-icg',
        'angiofluoresceinografia-afg',
        'usg-ocular',
        'eletrorretinograma-erg-de-campo-total',
        'erg-multifocal',
        'potencial-visual-evocado-pve',
        'pve-varredura',
        'potencial-acuidade-macular',
        'ceratometria',
        'exame-personalizado'
      ],
      'Informações cirúrgicas': [
        'dados-da-cirurgia',
        'anestesia',
        'produtos-utilizados',
        'patologia-a-ser-tratada',
        'anotacao-cirurgica',
        'intercorrencias',
      ],
      'Diagnóstico': [
        'hipotese-diagnostica',
        'cid'
      ],
      'Conduta': [
        'solicitacao-de-exames',
        'solicitacao-de-tratamento',
        'apa-avaliacaore-anestesica',
        'solicitacao-de-cirurgia',
        'encaminhamento',
        'prescricao-medicamentosa-v2',
        'oculos',
        'lentes-de-contato',
        'orientacao-pos-operatoria',
        'mais-Informacoes',
        'retorno',
        'atestados-medicos',
        'laudo-medico',
        'complemento-glaucoma',
        'doctor-assistant-result'
      ],
      'Anotações' : [
        'informacoes-adicionais-exam'
      ]
    }
}
